<template>
    <v-card flat class="">
        <v-container fluid>
            <h3>Kuesioner</h3>
        </v-container>
        <v-simple-table dense bord>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Nama</th>
                        <th>Link</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, idx) in table.items" :key="item.id">
                        <td>{{ idx + 1 }}</td>
                        <td>{{ item.nama }}</td>
                        <td><a :href="item.link" target="_blank">{{ item.link }}</a></td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiDelete, mdiPencil } from '@mdi/js'
export default {
    components: {
        // PilihMultiUser,
    },
    data() {
        return {
            praktik_id: this.$route.params.praktik_id,
            data: {
                nama_institusi: '',
				nama_prodi: '',
                profesi: '',
                stase: '',
                tanggal_awal: '',
                tanggal_akhir: '',
            },
            icons: {
                mdiDelete,
                mdiPencil,
            },
            tableOptions: {},
            table: {
                loading: true,
                // headers: [
                //     { text: 'No', align: 'start', sortable: false, value: 'no', },
                //     { text: 'Waktu', value: 'waktu', sortable: false },
                //     { text: 'Kegiatan', value: 'kegiatan', sortable: false },
                //     { text: '', value: 'actions', sortable: false },
                // ],
                items: [],
                totalItems: 0,
            },
            dialog: false,
            form: {
                editedIndex: -1,
                editedItem: {
                    id: '',
                    praktik_id: '',
                    user_id: '',
                    sikap: '',
                    keterampilan: '',
                    tindakan: '',
                    catatan: '',
                    mutu_kepatuhan_apd: '',
                    mutu_kepatuhan_tangan: '',
                    mutu_skp: '',
                    mutu_pkpo: '',
                    mutu_ikp: '',
                },
                defaultItem: {
                    id: '',
                    praktik_id: '',
                    user_id: '',
                    sikap: '',
                    keterampilan: '',
                    tindakan: '',
                    catatan: '',
                    mutu_kepatuhan_apd: '',
                    mutu_kepatuhan_tangan: '',
                    mutu_skp: '',
                    mutu_pkpo: '',
                    mutu_ikp: '',
                },
                pembimbings: [],
            },
        }
    },
    watch: {
        // tableOptions: {
        //     handler() {
        //         this.getDataFromApi()
        //     },
        //     deep: true,
        // },
    },
    computed: {
        formTitle() {
            return this.form.editedIndex === -1 ? 'Tambah Item' : 'Edit Item'
        },
    },
    created() {
        this.getDetail()
        this.getDataFromApi()
    },
    methods: {
        getDataFromApi() {
            axiosGetAuth('api/User/getKuesionerLinks')
                .then(response => {
                    this.table.items = response.data
                    this.table.totalItems = response.total
                    this.table.loading = false
                })
                .catch(error => {
                    alert(error)
                })
        },
        add() {
            this.form.editedItem = Object.assign({}, this.form.defaultItem)
            this.form.editedIndex = -1
        },
        editItem(item) {
            this.form.editedIndex = this.table.items.indexOf(item)
            this.form.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        getDetail() {
            const fmData = new FormData()
            fmData.append('id', this.praktik_id)
            axiosPostAuth('api/Praktik/getPraktik', fmData)
                .then(response => {
                    this.data = Object.assign({}, response.data)
                })
                .catch(error => {
                    alert(error)
                })
        },
    },
}
</script>